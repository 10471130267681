import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import customaxios from '../../helpers/axios-helper';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";

export default function Payee() {
  const [payeeList, setPayeeList] = useState([]);
  const [newPayee, setNewPayee] = useState({
    Name: "",
    Description: "",
    PayeeType: ""
  });
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await customaxios.get("Payee/GetAllPayee");
        setPayeeList(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const handleChange = (e) => {
    setNewPayee({ ...newPayee, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await customaxios.post("Payee/CreatePayee", newPayee);
      alert("Payee Created successfully!");
    } catch (error) {
      console.error(error);
      alert("Failed to Create Payee");
    }
  };

  const filteredPayees = payeeList.filter((payee) => {
    const payeeName = payee.name ? payee.name.toLowerCase() : ""; // Check if name exists and convert to lowercase
    return payeeName.includes(searchQuery.toLowerCase());
  });

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="Name">Payee:</label>
        <input
          type="text"
          id="Name"
          name="Name"
          value={newPayee.Name}
          placeholder="Name"
          onChange={handleChange}
        />
        <input
          type="text"
          id="Description"
          name="Description"
          placeholder="Description"
          value={newPayee.Description}
          onChange={handleChange}
        />
        <input
          type="text"
          id="PayeeType"
          name="PayeeType"
          placeholder="PayeeType"
          value={newPayee.PayeeType}
          onChange={handleChange}
        />
        <button type="submit">Create Payee</button>
      </form>

      <br />
      <br />
      <br />
      <input
        type="text"
        placeholder="Search payee"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <br />
      <br />
      <br />

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Payee ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>PayeeType</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPayees.map((payee) => (
              <TableRow key={payee.payeeId} component={Link} to={`/payee/${payee.payeeId}`}>
                <TableCell>{payee.payeeId}</TableCell>
                <TableCell>{payee.name}</TableCell>
                <TableCell>{payee.description}</TableCell>
                <TableCell>{payee.payeeType}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
