import React, { Component } from 'react';

class Sms_Status extends Component {
  state = {
    active: false,
    accBalance: 0,
  };

  componentDidMount() {
    // Call the function to make the GET request when the component mounts
    this.fetchData();
    console.log("called mounted");
  }

  fetchData = () => {
    fetch('https://app.notify.lk/api/v1/status?user_id=24082&api_key=3baAxq7z0qH63GfkzaQ9')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // Extract data from the response
        const { active, acc_balance } = data.data;
        console.log(data);

        // Update state variables with the extracted data
        this.setState({
          active,
          accBalance: acc_balance,
        });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  render() {
    const { active, accBalance } = this.state;

    return (
      <div>
        <p>SMS Pipe Active: {active ? 'true' : 'false'}</p>
        <p>SMS Credits: {accBalance}</p>
      </div>
    );
  }
}

export default Sms_Status;
