import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/auth-context';

import logo from '../assets/images/logo.jpeg';
import Nav from './nav';
import {useSelector,useDispatch} from "react-redux";
import { RootState } from "../Globals/GlobalInterface";
import {
  toggleProductName,
  ArchImage,
  Togglezeroproduct,
  ProductLables,
  discountvalue} from "../Actions/index";
import { useEffect, useState } from 'react';
import Switch from '@mui/material/Switch';
export default function Header() {
  const isAuth = useAuth();
  const [isSticky, setIsSticky] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Add state for dropdown menu
    const dispatch = useDispatch();
    const _globalstate = useSelector((state:RootState) => state);
  
    const ViewProductName = Boolean(_globalstate.productname);
    const handleSwitchChange = () => {
        dispatch(toggleProductName());
      };



      const rowlbl = Boolean(_globalstate.lable) 

  



  return (
    <header className={`header${isSticky ? ' sticky' : ''}`}>
      <div className="HeaderWrapper">
        <div className="logo">
          <Link to="/dashboard/voucher">
            <img  style={{ width: '150px', padding:'20px'}} className="header-logo"  src="https://cdn.havelockdental.com/Havelock%20Dental%20Logo.svg" alt="Havelock Dental Logo" />
           {
            /*
             <img className="header-logo" src={logo} alt="Cristal Code Logo" />
            */
           }
          </Link>
        </div>
      
        
        <div className="navheader">
          <Nav />
        </div>
        {/* Add dropdown button */}

     
        
        
      </div>
    </header>
  );
}
