import customaxios from "axios";
import Cookies from "js-cookie";
import { disableLoad } from "../components/loader";
import { UserLogin } from "../models/users/user-login";
import { removeCookie } from "./cookie-helper";
import { clearLSOnLogout, getAuthUserFromLS } from "./storage-helper";

const UNAUTHORIZED = 401;
const UNAUTHORIZED_MESSAGE = "Access token has expired. Please login again.";
const ISO_DATE_FORMAT = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?$/;


export const initAxios = () => {
    customaxios.defaults.headers.common = {'Authorization': `Bearer ${Cookies.get('JWT')}`};
}

initAxios();
//customaxios.defaults.baseURL = "https://localhost:7141/api/";
//customaxios.defaults.baseURL = "https://localhost:44362/api/";
customaxios.defaults.baseURL = "https://Cristal.havelockdental.com/api/";

//Interceptors
customaxios.interceptors.response.use( 
  response => {
    handleDates(response.data);   //Interceptor to parse dates
    return response;
  },
  error => {
    if(error.response) {
        var message = error.response.data;
        const status = error.response.status;

        console.log(error.response.data);
        console.log(error.response.status);
            
        //const {status} = error.response;
        //401 interceptor
        if (status === UNAUTHORIZED) {
          disableLoad();
          logout();
          alert(UNAUTHORIZED_MESSAGE);
        }
        else{
          alert(`${status}: ${message}`);
        }
    } 
    else if (error.request) {
        console.log(error.request);
    } 
    else {
        console.log('Error', error.message);
    }
    console.log(error.config);

    return Promise.reject(error);
 }
);

export default customaxios;



function handleDates(responseBody: any) {
  if (responseBody === null || responseBody === undefined || typeof responseBody !== "object") return responseBody;
    
  for (const key of Object.keys(responseBody)) {
    const value = responseBody[key];

    if (isIsoDateString(value)) responseBody[key] = new Date(value);
    else if (typeof value === "object") handleDates(value);
  }
}

function isIsoDateString(value: any): boolean {
  return value && typeof value === "string" && ISO_DATE_FORMAT.test(value);
}

const logout = () => {
  const authUser = getAuthUserFromLS();

  const user: UserLogin = {
    email: authUser.email, 
    password: '',
    location: 'web'
  };

  console.log(user);

  customaxios.post('auth/logout', user)
  .then((response) => {
    console.log(response);

    const data = response.data;

    if(data) {
      if(data.includes("success")) {
        removeCookie("JWT", new Date(sessionStorage.getItem('expiry')));
        clearLSOnLogout();

        window.location.replace('/');
      }
      else{
        alert('Something went wrong.');
      }
    }
    else{
      alert('Something went wrong.');
    }
  })
}