import React, { useState, useEffect } from "react";
import customaxios from "../../../helpers/axios-helper";
import { useAuthUser } from '../../../contexts/auth-context';
import { tr } from "date-fns/locale";
import "./Style/Style.css"
import Export_Tratment_log from "../Export_Data_Feature/Export_Tratment_log";
export default function Treatment_Log_report(){
    const [Logs, setLogs] = useState<any[]>([]);
    const AuthUser = useAuthUser();
    const [SelectDate, setSelectDate] = useState<Date>(new Date());
    const [day_total_collection, setDayTotalCollection] = useState(0);

  

    

 // Fetch the list of doctors when the component mounts
 useEffect(() => {
  const Formatted_SelectDate = SelectDate.toISOString().slice(0, 19).replace('T', ' ');

    customaxios
      .get(`TreatmentLog/SelectDayReport_byAllDoctors?Date=${Formatted_SelectDate}`)
      .then((response) => {
        setLogs(response.data);
        console.log(response)
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [SelectDate]);
  if(  AuthUser == null)
  {
    return(<>No auth</>)
  }
    return(
        <>
    <h2>Invoices { SelectDate.toLocaleDateString('en-GB')}</h2>
{/*
<h3> Total Collection {day_total_collection}</h3>
*/}
        <div>
        Select Date: <input type="date" value={SelectDate.toISOString().slice(0, 10)} onChange={(e) => setSelectDate(new Date(e.target.value))} />
      </div>
     <Export_Tratment_log SelectDate={SelectDate}/>
        {
            Logs?.map((log)=>(
                <div className="Doctor_Logs">
                  <h3>
                  {log.doctor.name}
           
                  </h3>
          
                  <p>Grand Total {(calculateCashTotal(log.logs) + calculateCardTotal(log.logs)).toLocaleString()} LKR</p>
<table>
            <thead>
                <tr>
                    <td>
                        Date

                    </td>
                    <td>
                        Treatment
                    </td>
                    <td>
                        Pt Name
                    </td>
                  {
                    /*
                    <td>
                        Contact
                    </td> */
                  }
                    <td>
                        Cash
                    </td>
                    <td>Card</td>

                </tr>
            </thead>
            <tbody>
            {
                    log.logs?.map((l)=>(
                        <tr>
                   <td>
                   {new Date(l.dateTime).toLocaleDateString('en-GB')}
                </td>
                   <td> {l.treatment}</td>
                    <td>{ l.patient.name}</td>
                    {
                    /*
                                     <td>{ l.patient.contact_No}</td>
 */
                  }
                    <td>
  {l.paymentMode === "Cash" ? l.amount.toLocaleString() : "-" }
</td>

<td>
  {l.paymentMode === "Card" ? l.amount.toLocaleString() : "-" }
</td>
                </tr>
                    ))
                }

<tr>
                    <td>

                    </td>
                    <td>
                    </td>
             
                    <td>
                        Sub Total
                    </td>
                    <td>
                    {calculateCashTotal(log.logs).toLocaleString()}
                    </td>
                    <td>                    {calculateCardTotal(log.logs).toLocaleString()}
</td></tr>

            </tbody>
        </table>



              
               
                </div>
            ))
        }
       </>
    )
}

//CurrentDayReport_byAllDoctors

//CurrentDayReport_By_doctor

//CurrentDayReport
const calculateCashTotal = (logs) => {
  const cashLogs = logs.filter((log) => log.paymentMode === "Cash");
  const cashTotal = cashLogs.reduce((total, log) => total + log.amount, 0);

  
  return cashTotal;
};

const calculateCardTotal = (logs) => {
  const cardLogs = logs.filter((log) => log.paymentMode === "Card");
  const cardTotal = cardLogs.reduce((total, log) => total + log.amount, 0);
  return cardTotal;
};