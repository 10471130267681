import React, { useEffect, useState } from "react";
import customaxios from "../helpers/axios-helper";
import Fuse from "fuse.js";
import { AuthProvider,useAuthUser } from "../contexts/auth-context";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate,Link } from "react-router-dom";

import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
export default function Customers() {

  const [searchTerm, setSearchTerm] = useState('');
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const AuthUser = useAuthUser();
    //get customers form api
    const [Customers_List, setItems] = useState([]);
    //axios call to get items
    useEffect(() => {
        customaxios.get('Customer/GetCustomers/')
        .then((response) => {
            console.log(response);
            console.log(response.data);
            setItems(response.data);

        })
    }, []);

      // Create a Fuse instance for fuzzy search
  const fuse = new Fuse(Customers_List, {
    keys: ["customerName"],
    includeScore: true,
    threshold: 0.3, // Adjust the threshold value based on the desired search accuracy
  });


    const [selectedValue, setSelectedValue] = useState('');

    const navigate = useNavigate();
 
    const handleChange = (event, newValue) => {
      setSelectedValue(newValue);
      console.log(newValue);
      
    }
 const filteredCustomers = searchTerm
    ? fuse.search(searchTerm).map(({ item }) => item)
    : Customers_List;
  
    return(
<>
  {AuthUser.role === "Director"||"Developer" ? (
    <div className="CustomerList">
       <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search customers"
          />


      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Customer Name</TableCell>
              <TableCell align="left">Address</TableCell>
              <TableCell align="left">Group Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCustomers.map((row) => (
              <TableRow 
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">
                  <Link to="/CustomerInformation" state={{customerId: row.customerId}}>
                    {row.customerName}
                  </Link>
                </TableCell>
                <TableCell align="left">{row.addressline2}</TableCell>
                <TableCell align="left">{row.group_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  ) : (
    <>no auth</>
  )}
</>

    );
}