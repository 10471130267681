import React, { useEffect, useState } from "react";
import customaxios from "../../../helpers/axios-helper";
import { Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";

export default function CategoryList() {
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await customaxios.get("VoucherCategory/GetAllCategories");
        setCategories(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredCategories = categories.filter((category) =>
    category.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <input
        type="text"
        placeholder="Search category"
        value={searchQuery}
        onChange={handleSearch}
      />

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category ID</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>LineItem</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCategories.map((category) => (
              <TableRow key={category.voucherCategoryId}>
               
                <TableCell>
                  <Link to={`/Category/${category.voucherCategoryId}`}>
                    {category.voucherCategoryId}
                  </Link>
                </TableCell>
                <TableCell>{category.description}</TableCell>
                <TableCell>
                  <Link to={`/lineitem/${category.voucherCategoryId}`}>
                    View Line items
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
