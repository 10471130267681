
import React, { useEffect, useState } from "react";  
import { useLocation } from "react-router-dom";
import customaxios from "../helpers/axios-helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function LedgerRow(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
  
    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            {row.ammendments.length> 0?
            (<IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>):
            (null)
        
        }
            
          </TableCell>
         
          <TableCell align="left">{row.description}</TableCell>
          <TableCell align="left">{row.type}</TableCell>
          <TableCell align="left">{row.stringbalance}</TableCell>
          <TableCell align="left"></TableCell>
          <TableCell align="left">{row.vchno}</TableCell>
        </TableRow>
        <TableRow>
           <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  History
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Item</TableCell>
                      <TableCell align="right"> History id</TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                    row.ammendments.map((historyRow) => (
                      <TableRow key={historyRow.date}>
                       <TableCell align="right">
                         
                         </TableCell>
                        <TableCell>{historyRow.ammendmentnote}</TableCell>
                        <TableCell align="right"> {historyRow.amtId}</TableCell>
                      
                        <TableCell align="right">
                         
                        </TableCell>
                        <TableCell align="right">
                         
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell> 
        
            
                     
        </TableRow>
      </React.Fragment>
    );
  }
  