import $ from 'jquery';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import { useAuth } from '../contexts/auth-context';
import Logout from './logout';
import {useSelector,useDispatch} from "react-redux";
import { ReactComponent as UserSVG } from '../assets/vectors/user.svg';
import { RootState } from "../Globals/GlobalInterface";
import { useAuthUser } from '../contexts/auth-context'; 
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  toggleProductName,
  Togglezeroproduct,
  ProductLables,
  discountvalue} from "../Actions/index";
import Switch from '@mui/material/Switch';

import * as React from 'react';
import { parse } from 'path';

export default function Nav() {
  const AuthUser = useAuthUser();
    const dispatch = useDispatch();
    const _globalstate = useSelector((state:RootState) => state);
  
    const ViewProductName = Boolean(_globalstate.productname);
    const handleSwitchChange = () => {
        dispatch(toggleProductName());
      };


    const ViewzeroProducts = Boolean(_globalstate.zeroqty);

      const handleZeroproductSwitchChange = () => {
        dispatch(Togglezeroproduct());
      };
    
      const rowlbl = Boolean(_globalstate.lable) 

      const handlelables = () => {
        dispatch(ProductLables());
        console.log(rowlbl);
      };
      const discount =_globalstate.Discount 

      const handlediscont = (discount) => {
        dispatch(discountvalue(discount));
        console.log(discount);
      };


    const [isOpen, setIsOpen] = useState(false);
    const isAuth = useAuth();

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    }

    return (
       

         
        <nav className='RightNav'>
       {isAuth ?(   <div className="Cart">
      
          <Link to="/Patient_Transaction">Sales</Link>
          <br />
          <Link to="/dashboard/voucher">Vouchers</Link>
      

          </div>):null}
            <div className="nav-user">
                <button onClick={toggleDropdown} className={isOpen ? 'open' : ''}><UserSVG /></button>
                
                <div id="nav-user-dropdown" className={isOpen ? 'nav-user-dropdown open' : 'nav-user-dropdown'}>
                    {
                        isAuth ? 
                        <>
                       
      <div>{AuthUser?.name ? AuthUser.name :"" }</div>
      <>
      {AuthUser?.role == "Director"||"Developer" ?(<Link to="/test">test</Link>
      
      ):null}
      </>

                             
                            <Logout />
                        </>
                        :
                        <>
                            <Link to="/">Login</Link>
                            <Link to="/register">Register</Link>
                        </>
                        
                    }
      <div>V0.29 beta</div>

                </div>
            </div>
           
        </nav>
    );
}