import React, { useRef,useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import View_Vouchers_row_Head from '../View_Vouchers_row_Head';
import View_Vouchers_row from '../View_Vouchers_row';

import LetterHead from '../../../../assets/Print/SVG/Letter Head.svg';
import Footer from '../../../../assets/Print/SVG/Footer.svg';
interface PrintObjectProps {
  voucher: any;
}

const PrintObject: React.FC<PrintObjectProps> = ({ voucher }) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [visible , setVisible] = useState(false);
  
  
  const handlePrint =
    useReactToPrint(
  
      {
      content: () => componentRef.current,
    }) ;
  

  const handlePrintInNewTab = () => {
    const printWindow = window.open('', 'Print');
    printWindow.document.write(componentRef.current?.innerHTML || '');
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();

  };
  async function handlePrint_options() {
    setVisible(true);
    await new Promise((resolve) => setTimeout(resolve,.00001));
    handlePrint();
    setVisible(false);
  }
  

  return (
    <div>
      <button className='button_one' onClick={handlePrint_options}>Print</button>
      <div ref={componentRef}>
<div className={`printvoucher ${visible ? 'visible' : 'hidden'}`}> 
{/*<div className={`printvoucher`}>*/}
  <div className="letterhead">
  <img src={LetterHead} alt="LetterHead" />
  </div>
  <div className="voucherbody">
    <div className="details">
    <div className="createdby">
  <p className='Title'>Created</p>
  {voucher.created_By && (
    <>
      <p className="createdby _user">{voucher.created_By.firstName} {voucher.created_By.lastName}</p>
      <p className="created _Date">({new Date(voucher.created_DateTime).toLocaleString()})</p>
    </>
  )}
</div>

{voucher.verifiedBy ? (
  <div className="Verifiedby">
    <p className="Title">Verified</p>
    <p className="Verifiedby _user">
      {voucher.verifiedBy.verified_User.firstName}{' '}
      {voucher.verifiedBy.verified_User.lastName}
    </p>
    <p className="Verified _Date">
      ({new Date(voucher.verifiedBy.verified_Date_Time).toLocaleString()})
    </p>
  </div>
) : (
  <>Pending verification</>
)}


<div className="approvedby">
  <p className='Title'>Approved</p>
  {voucher.approvedBy ? (
    <>
      <p className="approvedby _user">{voucher.approvedBy.approved_User.firstName} {voucher.approvedBy.approved_User.lastName}</p>
      <p className="approved _Date">({new Date(voucher.approvedBy.approved_Date_Time).toLocaleString()})</p>
    </>
  ) : (
  <>Pending Approval</>
  )}
</div>

    </div>
    <div className="voucherdetails">
      <div className="vouchernumber _field">
        <p>Voucher Number: {voucher.voucherId}</p>
      </div>
      <div className="Branch _field">
        <p>Branch: {voucher.branch?.name}</p>
      </div>
      <div className="Category _field">
        <p>Category: {voucher.category.description}</p>
      </div>
      <div className="Payee _field">
        <p>Payee: {voucher.payee?.name || 'N/A'}</p>
      </div>
      <div className="Payment Mode _field">
        <p>Payment Mode: {voucher.paymentMode === 'cheque' ? `(${voucher.paymentMode} ${voucher.cheque_no})` : voucher.paymentMode}</p>
      </div>
      <div className="Total _field">
        <p>Total: {voucher.voucher_Total.toLocaleString()}</p>
      </div>
      <div className="Invoice _field">
        <p>Invoice Number: {voucher.invoiceNumber ? voucher.invoiceNumber : 'N/A'}</p>
        <p>Invoice date: {voucher.invoice_date ? new Date(voucher.invoice_date).toLocaleString() : 'N/A'}</p>
      </div>
      </div>
 <div className="voucheritems">
 <Voucheritems  voucher={voucher}/>
 </div>
    </div>

    <div className="footer">
    <img src={Footer} alt="Footer" />

    </div>
</div>

      </div>
    </div>
  );
};

export default PrintObject;
function Voucheritems({voucher}){

  return(

    <div className="voucheritems_print">
    <br/>
      
        <br/>
        <br/>
        <br/>
    <table className='printtablevoucheritems'>
        <thead>
          <tr>
            <th>Item ID</th>
            <th>Description</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {voucher.voucherItems.map((item) => (
            <tr key={item.voucherItemId}>
              <td>{item.voucherItemId}</td>
              <td>{item.voucherDescription}</td>
              <td>{item.voucherAmount.toLocaleString()} LKR</td>
            </tr>
          ))}
           <tr >
              <td></td>
              <td><strong>Total</strong></td>
              <td><strong>{voucher.voucher_Total.toLocaleString()} LKR</strong></td>
            </tr>
        </tbody>
      </table>
    </div>
  )
}