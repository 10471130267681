import customaxios from '../../helpers/axios-helper';
import { useState } from "react";
import $ from "jquery";
import { Link, Navigate, useNavigate  } from "react-router-dom";

import { useAuth, useAuthUpdate } from '../../contexts/auth-context';
import { UserLogin } from "../../models/users/user-login";

import { disableLoad, enableLoad } from "../../components/loader";
import { addCookie } from "../../helpers/cookie-helper";
import { addToLSOnLogin } from "../../helpers/storage-helper";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { id } from 'date-fns/locale';


export default function Login() {
    const [form, setForm] = useState({
        email: "",
        password: ""
    });
    const notify = () => 
    toast.success("Login Successful")
    const isAuth = useAuth();
    const toggleAuth = useAuthUpdate();
    
    const navigate = useNavigate();

    function updateForm(value) {
        return setForm((prev) => {
            console.log(value);
            return { ...prev, ...value };
        });
    }

    const processLogin = (e?) => {
        e.preventDefault();

        const user: UserLogin = {
            email: form.email, 
            password: form.password,
            location: 'web'
        };

        console.log(user);

        enableLoad("Logging you in...");

        customaxios.post('auth/login', user)
        .then((response) => {
            console.log(response);
    
            const data = response.data;

            disableLoad();
            
            if(data) {
                //Check if error
                if(typeof(data) !== 'string'){
                    $('#login-form').trigger("reset");
    
                    console.log(data);
    
                    const user = data.user;
                    const role : string = data.role;
                    const JWT : string = data.jwt;
                    const expiry : Date = new Date(data.expiry);
    
                    addCookie('JWT', JWT, expiry);  
    
                    let name : string = '';
                    role === 'Developer' ? name = role : name = user.firstName + ' ' + user.lastName;
    
                    addToLSOnLogin(user.id,name, user.email, role, expiry); //Add to local storage
    
                    toggleAuth();
    
                    //alert('You have been logged in successfully.');
                    notify();
                    setTimeout(() => {
                        role === 'Director' || 'Developer' ? navigate('/dashboard/voucher') : navigate('/dashboard/voucher');
                    }, 1000)
                }
                else{
                    alert(data);
                }
            }
            else{
                alert('Something went wrong');
            }
        })
    }

    return !isAuth ? (
        <section className="login">
            <h1>Login</h1>

            <div className="login-form-wrapper">
                <form className="login-form" id="login-form" onSubmit={processLogin}>
                    <fieldset id="login-form">
                        <div className="floating-input">
                            <input type="email" pattern="[A-Za-z0-9._+-]+@[A-Za-z0-9 -]+\.[a-z]{2,}" title="Ex: john@zmail.com" id="login-email" placeholder=" " required
                            onChange={(e) => updateForm({ email: e.target.value.trim() })} />

                            <label htmlFor="login-email">Email</label>
                        </div>

                        <div className="floating-input">
                            <input type="password" id="login-password" placeholder=" " required
                            onChange={(e) => updateForm({ password: e.target.value.trim() })} />

                            <label htmlFor="login-password">Password</label>
                        </div>

                        <Link className='login-form-forgot' to={"/forgot-password"}>Forgot password?</Link>

                        <div className="inline-btns">
                            <input type="submit" id="login-submit" value='Login' />
                        </div>
                    </fieldset>
                </form>
            </div>
        </section>
    ) : (
        <Navigate to={'/Home'} />
    );
}