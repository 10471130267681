import React, { useEffect, useState } from "react";
import CategorySelect from "./SelectCategory";
import CreateVoucherItem from "./CreateVoucherItem";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useAuth,useAuthUser } from '../../../contexts/auth-context';
import { notify } from 'react-notify-toast';
import VoucherTable from "./VoucherTable";
import VoucherInfo from "./VoucherInfo";
import customaxios from "../../../helpers/axios-helper";
import { toast } from 'react-toastify';

export default function CreateVoucher() {
  const [voucherItems, setVoucherItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedBranch, setselectedBranch] = useState();
  const [payee, setPayee] = useState("");
  const [PaymentModeSelected, setPaymentModeSelected] = useState("");
  const [ChequeNumberEntered, setChequeNumberEntered] = useState("");
  const [invocie_date, setinvocie_date] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  useEffect(() => {

    console.log(ChequeNumberEntered)

  }, [ChequeNumberEntered]);
  const AuthUser = useAuthUser();
  const handleAddItem = (item) => {
    setVoucherItems([...voucherItems, item]);
    console.log(voucherItems)
  };
  const handleCategorySelect = (event) => {
      setSelectedCategory(event);
      console.log(selectedCategory)
  };
  const handlePayeeChange = (payeeName) => {
    setPayee(payeeName);
  };
  const handleDeleteItem = (index) => {
    setVoucherItems(voucherItems.filter((_, i) => i !== index));
  };
  const handlePaymentModeSelected = (Paymentmode) => {
    console.log(Paymentmode)
    setPaymentModeSelected(Paymentmode);
    console.log(PaymentModeSelected)
  };
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);

  console.log(AuthUser)
  const handleNoteAdd = (index, note) => {
    setSelectedItemIndex(index);
    setVoucherItems(prevItems => {
      const updatedItems = [...prevItems];
      updatedItems[index] = { ...updatedItems[index], note };
      return updatedItems;
    });
  };
  const handleInvoiceAdd = (index, Invoice_Number) => {
    setVoucherItems(prevItems => {
      const updatedItems = [...prevItems];
      updatedItems[index] = { ...updatedItems[index], Invoice_Number };
      console.log(voucherItems)
      return updatedItems;
    });
  };
  const handleSaveVoucher = () => {
    // Check if any of the required fields is null
    if (!selectedCategory || !AuthUser || !voucherItems || !PaymentModeSelected || !payee) {
        toast.error("Please fill all the required fields");
        return;
    }

    const voucher = {
        category: {
            description: selectedCategory
        },

        Cheque_no: ChequeNumberEntered,
        created_By: {
            id: AuthUser.id
        },
        Branch: {
          branchId: selectedBranch
        },
        payee: {
          PayeeId: payee
        },
        Invoice_date: invocie_date,
        InvoiceNumber: invoiceNumber,
        voucherItems: voucherItems,
        Paied_To: payee,
        PaymentMode: PaymentModeSelected,
        Voucher_State: "Created"
    }

    customaxios.post('voucher/addvoucher', voucher)
        .then(response => {
            console.log(response);
            toast.success("Voucher Submitted Successfully");
            setVoucherItems([]);
            setSelectedCategory("");
            setselectedBranch(null);
            setPayee("");
            setPaymentModeSelected("");
            setChequeNumberEntered("");
            setinvocie_date(null);
            setInvoiceNumber("");
            window.location.reload();
        })
        .catch(error => {
            console.log(error);
            toast.error("Voucher Submission Failed")
        });


};

  return (
    <div className="Create_Payment_Voucher">
      <br/>
      <br/>
      <br/>
      <VoucherInfo 
      onChequeNumberEntered={setChequeNumberEntered} 
      onPaymentModeSelected={handlePaymentModeSelected} 
      payee={payee} onPayeeChange={handlePayeeChange} 
      onCategorySelect={handleCategorySelect} 
      onBranchSelected={setselectedBranch}
      onInvoiceDateTimeSelect={setinvocie_date}
      onInvoiceNumberEntered={setInvoiceNumber}
      />
     
  <CreateVoucherItem onAddItem={handleAddItem} />

     <div className="Items">
  <VoucherTable onInvoiceAdd={handleInvoiceAdd}  onNoteAdd={handleNoteAdd} voucherItems={voucherItems} onDelete={handleDeleteItem} />
    
  </div>
  <button className="button_one" onClick={handleSaveVoucher}>Create Voucher </button>

    </div>
  );
}
