import React, { useState, useEffect } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import customaxios from "../../helpers/axios-helper";
import "./Patient.css"
import Sms_Status from "../SMS_Status/Sms_Status";
import { toast } from "react-toastify";
import Treatment_Log_report from "./TreatmentLog_Report/Treatment_Log_report";
interface TreatmentLogCreateModel {
  p_Name: string;
  p_Contact_No: string;
  DoctorId: number | null;
  Amount: number | null;
  PaymentMode: string;
  Treatment: string;
  SendSms:boolean;
}

export default function Patient_Transactions() {
  const [doctors, setDoctors] = useState<any[]>([]);
  const [selectedDoctor, setSelectedDoctor] = useState(0);
  const [key_logs,setkey_logs] = useState(1);
  const [treatmentLog, setTreatmentLog] = useState<TreatmentLogCreateModel>({
    p_Name: "",
    p_Contact_No: "",
    Treatment: "",
    DoctorId: null,
    Amount: null,
    SendSms:false,
    PaymentMode: "Cash", // Default to "Cash"
  });

  // Fetch the list of doctors when the component mounts
  useEffect(() => {
    customaxios
      .get(`Doctor/GetallDoctors`)
      .then((response) => {
        setDoctors(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedDoctor(newValue);
    setTreatmentLog({
      ...treatmentLog,
      DoctorId: doctors[newValue].doctorId,
    });
  };
  const handlePaymentModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTreatmentLog({
      ...treatmentLog,
      PaymentMode: value,
    });
  };

  const handleSMSChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    
    setTreatmentLog((prevTreatmentLog) => ({
      ...prevTreatmentLog,
      SendSms: checked,
    }));
  };
  
  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTreatmentLog({
      ...treatmentLog,
      [name]: value,
    });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    console.log(treatmentLog)
    if(treatmentLog.DoctorId == null){
        toast.error("select doctor")
    }
    else
    {
         // Submit treatment log with the attached DoctorId
    customaxios.post("TreatmentLog/AddLog", treatmentLog).then((response) => {
        // Handle the response as needed
  toast.success("Log Added")
  console.log(response)
  setkey_logs(key_logs+ 1);
  setTreatmentLog({
      p_Name: "",
      p_Contact_No: "",
      Treatment: "",
      DoctorId: null,
      Amount: null,
      SendSms:true,
      PaymentMode: "Cash", // Default to "Cash"
    })
      }).catch((error) =>(
          toast.error(error)
      ));
    }
   
  };

  return (
    <>
<Sms_Status/>
<Tabs value={selectedDoctor} onChange={handleTabChange}>
        {doctors.map((doctor, index) => (
          <Tab label={doctor.name} key={index} />
        ))}
      </Tabs>
      <div className="treatment_log_Wrapper">
      <div className="form-container">
      <form onSubmit={handleSubmit}>
          <TabPanel value={selectedDoctor} index={selectedDoctor}>
            
          <div className="form-title">Treatment Log</div>
            <div className="doctor-info">
            {/*   Doctor ID: {doctors[selectedDoctor]?.doctorId} */}
              <br />
              {treatmentLog?.DoctorId == null ? ("Select Doctor") : (<>Doctor: {doctors[selectedDoctor]?.name}</>)}
            </div>
            <div>
              <label>
              Treatment:
                <input
                  type="text"
                  name="Treatment"
                  value={treatmentLog.Treatment}
                  onChange={handleFormChange}
                />
              </label>
            </div>
            <div>
              <label>
                Patient Name:
                <input
                  type="text"
                  name="p_Name"
                  value={treatmentLog.p_Name}
                  onChange={handleFormChange}
                />
              </label>
            </div>
            <div>
              <label>
                Contact Number:
                <input
                  type="text"
                  name="p_Contact_No"
                  value={treatmentLog.p_Contact_No}
                  placeholder="94000000000"
                  onChange={handleFormChange}
                />
              </label>
            </div>
            <div>
            <label>
              Amount:
              <input
                type="number"
                name="Amount"
                value={treatmentLog.Amount || ''}
                onChange={handleFormChange}
              />
            </label>
          </div>
            <div>
  <label>Payment Mode:</label>
  <div className="radio-group">
    <table>
        <tr>
            <td>Cash</td>
            <td> <input
        type="radio"
        name="PaymentMode"
        value="Cash"
        checked={treatmentLog.PaymentMode === "Cash"}
        onChange={handlePaymentModeChange}
      /></td>
        </tr>
        <tr>
            <td>Card</td>
            <td>  <input
        type="radio"
        name="PaymentMode"
        value="Card"
        checked={treatmentLog.PaymentMode === "Card"}
        onChange={handlePaymentModeChange}
      /></td>
        </tr>
        <tr>
    <td>Send SMS</td>
    <td>
      <label className="switch">
        <input
          type="checkbox"
          name="SendSms"
          checked={treatmentLog.SendSms}
          onChange={handleSMSChange}
        />
        <span className="slider round"></span>
      </label>
    </td>
  </tr>
    </table>
    
  </div>
</div>

            <button type="submit">Submit</button>
          </TabPanel>
        </form>
    </div>
    <div className="logs">
   
   <Treatment_Log_report key={key_logs}/>
      </div>
      </div>
     
    </>
  );
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}


function Treatment_Logs(){
  return(<>
  
  <div className="logs">
     </div>
    
    </>)
}