import React, { useEffect, useState } from "react";  
import { useLocation } from "react-router-dom";
import customaxios from "../helpers/axios-helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import LedgerRow from './LedgerRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { Link } from "react-router-dom";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Customer_ledger from "./Customer_ledger";
import { CryptoCard } from "react-ui-cards";
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

export default function CustomerInformation(props) {
    const location = useLocation();
    console.log(location);
    console.log("state " +location.state);
  //  console.log(props);
    const notify = () => 
    toast.success(CustomerLedgers.length +" Products Loaded");
    //set customer ledgers 
    const [CustomerLedgers, setItems] = useState([]);
    //axios call to get items post request
    useEffect(() => {
        customaxios.post('Customer/GetCustomerById/',{
            Customerid: location.state.customerId,
        })
        .then((response) => {
            console.log(response);
            setItems(response.data.transaction_Ledgers);
            console.log(response.data);
        })
    }, []);
   
    console.log(location.state)
 return (
<div className="customerinformation">


<CryptoCard 
                    //add custom style 
                    className="CryptoCard" 
                    key={location.state.id}
                    currencyName={location.state.customerName}
                    trendDirection={1}
                    currencyPrice="$ 0.00"
                    currencyShortName={location.state.group_name}
                    trend="0.00%"
                    chartData={[100,200, 150, 125,250]}
                    icon={<img src="../assets/images/logo.jpeg"/>}
                    //add icon to icon node type
                    
                    chartColor="grey"

                   
                />

<Link state={location.state} to="/Customerorders" >Customer order</Link>

<TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell align="left"></TableCell>
            <TableCell align="left">description</TableCell>

            <TableCell align="left"></TableCell>
            <TableCell align="left">Type</TableCell>
            <TableCell align="left">Balance</TableCell>
            <TableCell align="left">Voucher no</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {CustomerLedgers.map((row) => (
            <LedgerRow key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer></div>
 );
}