import { Link } from 'react-router-dom';

export function Voucheritems({ voucherItems }) {
    const items = Array.isArray(voucherItems) ? voucherItems : [voucherItems];
  
    return (
    <>
    
    {items.map((item) => (
        <tr key={item.voucherItemId}>
        <td>{item.voucher.created_DateTime ? item.voucher.created_DateTime.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) : "-"}</td>
        <td>{item.voucherItemId ? item.voucherItemId : "-"}</td>
                <td>{item.voucherDescription ? item.voucherDescription : "-"}</td>
                <td>{item.note ? item.note : "-"}</td>
                <td>{item.invoice_Number ? item.invoice_Number : "-"}</td>
                <td>
                  {item.voucherAmount ? item.voucherAmount.toLocaleString() : "-"} LKR
                </td>
                <td>
                   <Link to={`/Voucher/${item.voucher.voucherId}`}>
                   View Voucher
                  </Link>
                </td>
              </tr>
            ))}
  
    </>
         
    );
  }
  