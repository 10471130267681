import productnamereducer from "./ProductName";
import { combineReducers } from "redux";
import zeroqtyreducers from "./ZeroQty"; 
import ProductLables from "./ViewProductLables"; 
import DiscountValue from "./DiscountValue"; 
import Archimagereducer from "./ArchImage"; 
const allReducers = combineReducers({
    productname: productnamereducer,
    zeroqty: zeroqtyreducers,
    lable:ProductLables,
    Discount:DiscountValue,
    ArchImages:Archimagereducer,
});
export default allReducers;