import React, { useState, useEffect, useCallback } from 'react';
import customaxios from '../../../../helpers/axios-helper';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { TablePagination } from '@material-ui/core';
import { Voucheritems } from './Voucheritems';

interface Category {
  id: number;
  name: string;
  description: string;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function Voucher_itemBy_Category() {
  const { id } = useParams();
  console.log(id);
  const classes = useStyles();
  const [category, setCategory] = useState<Category | null>(null);
  const [voucherItemsList, setVoucherItemsList] = useState([]); // Renamed state variable
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalVouchers, setTotalVouchers] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pagedVouchers, setPagedVouchers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    customaxios
      .get(`/VoucherCategory/GetCategoryById/${id}`)
      .then((response) => {
        setCategory(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const fetchData = useCallback(async (pageNumber, pageSize, search) => {
    try {
      const response = await customaxios.get(
        `/Voucher/GetCategor_LineItem_ById?CategoryId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}`
      );
      console.log("response.data", response.data);
      setTotalVouchers(response.data.totalVouchers);
      setTotalPages(response.data.totalPages);
      setPagedVouchers(response.data.vouchers);
      setVoucherItemsList(response.data.vouchers); // Update the state variable here
      console.log(response.data.vouchers);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchData(currentPage, pageSize, searchTerm);
  }, [currentPage, pageSize, searchTerm, fetchData]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const handleRowsPerPageChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  return (
    <div>
      {category ? (
        <div className="voucherView">
           <div className="voucheritems">
        <br />
        <br />
        <br />
        <br />
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Item ID</th>
              <th>Description</th>
              <th>Note</th>
              <th>Invoice</th>
              <th>Amount</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
          {voucherItemsList && voucherItemsList.length > 0 ? (
            voucherItemsList.map((voucher) => (
              <Voucheritems key={voucher.id} voucherItems={voucher} />
            ))
          ) : (
            <div>No vouchers found.</div>
          )}
            
  
           
          </tbody>
        </table>
      </div>
          
          <TablePagination
            className="pagination-bar"
            component="div"
            count={totalVouchers}
            page={currentPage - 1}
            onPageChange={handlePageChange}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            labelRowsPerPage="Results per page:"
            onRowsPerPageChange={handleRowsPerPageChange}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} of ${count} (page ${currentPage} of ${totalPages})`
            }
          />
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}
