import customaxios from '../../../../helpers/axios-helper';

import { Voucheritems } from '../VoucherByCategory/Voucheritems';

import React, { useState } from 'react';

const Voucher_Item_report: React.FC = () => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [voucherItems, setVoucherItems] = useState<any[]>([]);
  const [Report_Data, setReport_Data] = useState<any[]>([]);

  
  const fetchData = () => {
    // Format the dates as strings to send in the query parameters
    const formattedStartDate = startDate.toISOString().slice(0, 19).replace('T', ' ');
    const formattedEndDate = endDate.toISOString().slice(0, 19).replace('T', ' ');
    setReport_Data([])
    setVoucherItems([])
    // Make the API call using Axios
    customaxios
      .get(`VoucherItem/GetVoucherItemsWithPaymentModeTotals?startDate=${formattedStartDate}&endDate=${formattedEndDate}`)
     // .get(`VoucherItem/GetVoucherItems?startDate=${formattedStartDate}&endDate=${formattedEndDate}`)
      .then((response) => {
        setVoucherItems(response.data.voucherItems);
        setReport_Data(response.data.report);
        console.log(response.data)
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };
  const voucherItems_total = voucherItems.reduce((total, item) => total + (item.voucherAmount || 0), 0);  return (
    <div>
      <div>
        Start Date: <input type="date" value={startDate.toISOString().slice(0, 10)} onChange={(e) => setStartDate(new Date(e.target.value))} />
      </div>
      <div>
        End Date: <input type="date" value={endDate.toISOString().slice(0, 10)} onChange={(e) => setEndDate(new Date(e.target.value))} />
      </div>
      <button className='button_one' onClick={fetchData}>Search</button>

      
      <table>
  <thead>
    <tr>
      <th>Payment Mode</th>
      <th>Payment Amount</th>
    </tr>
  </thead>
  <tbody>
    {Report_Data && Report_Data.map(paymentMode => (
      <tr key={paymentMode.PaymentMode}>
        <td>{paymentMode.paymentMode}</td>
        <td>{paymentMode.totalSum.toLocaleString()}</td>
      </tr>
    ))}
    <tr>
      <td><strong>Grand Total</strong></td>
      <td>
      <strong>
        {/* Add debugging */}
        {Report_Data &&
          Report_Data.reduce((total, paymentMode) => {
            console.log("Total:", total); // Debugging line
            console.log("PaymentMode.TotalSum:", paymentMode.totalSum); 
            // Debugging line
            return total + paymentMode.totalSum;
          }, 0).toLocaleString()
        }
      </strong>
    </td>
    </tr>
  </tbody>
</table>


      <div className="voucheritems">
        <br />
        <br />
        <br />
        <br />
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Item ID</th>
              <th>Description</th>
              <th>Note</th>
              <th>Invoice</th>
              <th>Amount</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
          {voucherItems && voucherItems.length > 0 ? (
            voucherItems.map((voucher) => (
              <Voucheritems key={voucher.id} voucherItems={voucher} />
            ))
          ) : (
            <div>No vouchers found.</div>
          )}
            
  
           <tr>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td>
    {voucherItems_total.toLocaleString()} LKR
</td>
<td></td>

           </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Voucher_Item_report;
