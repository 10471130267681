import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import SwipeableViews  from "react-swipeable-views";

import CreateVoucher from "./Create_Voucher";
import VoucherInfo from "./View_Vouchers";
import VoucherCategory from "../VoucherCategory/Voucher_Category";

import View_Vouchers_to_Verify from "./View_Vouchers_to_Verify";
import View_Vouchers_to_Approve from "./View_Vouchers_to_Approve";
import Branches from "../../Branches/Branches";
import Payee from "../../Payee/Payee";
import Voucher_Item_report from "./VoucherItems/VoucherItems"
export default function VoucherDashboard() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
   
<>
<div style={{ overflowX: 'auto' }}>
  <Tabs value={value} onChange={handleChange}
    style={{
      display: 'flex',
      alignItems: 'center',
      minWidth: '100%',
      paddingLeft: '24px',
      paddingRight: '24px'
    }}>
    <Tab label="Create Voucher" />
    <Tab label="View Vouchers" />
    <Tab label="View Vouchers_to_Verify" />
    <Tab label="View Vouchers_to_Approve" />
    <Tab label="Voucher Category" />
    <Tab label="Voucher Items Report" />
    <Tab label="Payee" />
    <Tab label="HDC Branches" />
  </Tabs>
</div>

      <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
        <Box >
          <CreateVoucher />
        </Box>
        <Box >
          <VoucherInfo />
        </Box>
        <Box >
          <View_Vouchers_to_Verify />
        </Box>
        <Box >
          <View_Vouchers_to_Approve />
        </Box>
        <Box >
          <VoucherCategory />
        </Box>
        <Box >
          <Voucher_Item_report />
        </Box>
        <Box >
          <Payee />
        </Box>
        <Box >
          <Branches />
        </Box>
      </SwipeableViews>
</>
      
   
  );
}
