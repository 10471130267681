import customaxios from "../../helpers/axios-helper";
import { useEffect, useState } from "react";
import React from "react";
export default function TestAuth() {

    //get items from api 
    const [items, setItems] = useState([]);
    //axios call to get items
    useEffect(() => {
        customaxios.get('auth/getfruitsauth/getfruitsauthenticated')
        .then((response) => {
            console.log(response);
            setItems(response.data);
        })
    }, []);
    return (
        <div>
            <h1>Test Auth</h1>
            <ul>
                {items.map((item) => (
                    <li key={item}>{item}</li>
                ))}
            </ul>
        </div>

    );
    
}