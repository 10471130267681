import { Route, Routes } from "react-router-dom";

import './styles/styles.css';

import Footer from "./components/footer";
import Loader from "./components/loader";
import Alert from "./components/custom-alert";
import Register from "./components/pages/register";
import Login from "./components/pages/login";
import { AuthProvider,useAuthUser } from "./contexts/auth-context";
import { useEffect } from "react";
import TestAuth from "./components/pages/testauth";

import 'chartjs-adapter-date-fns';
import Chart from 'chart.js/auto';
import {CategoryScale} from 'chart.js'; 
import ResetPassword from "./components/pages/reset-password";
import ForgotPassword from "./components/pages/forgot-password";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Customers from "./Customers/CustomerList";
import CustomerInformation from "./Customers/CustomerInformation";
import Header from "./components/header";
import BasicSpeedDial from "./components/SpeedDial";
import BasicTabs from "./Customers/dash";
import Customerorders from "./Customers/Customerorders";
import Create_Voucher from "./components/Payment/Voucher/Create_Voucher";
import Voucher from "./components/Payment/Voucher/Voucher";
import VoucherInfo from "./components/Payment/Voucher/View_Vouchers";
import VoucherDashboard from "./components/Payment/Voucher/VoucherDashboard";
import VoucherByPayee from "./components/Payment/Voucher/VoucherByPayee/VoucherByPayee";
import Payment_Category from "./components/Payment/Voucher/VoucherByCategory/Payment_Category";
import AddImplant from "./Inventory/Implants/Implants";
import Treatment_Log_report from "./components/Patient/TreatmentLog_Report/Treatment_Log_report";
import Voucher_itemBy_Category from "./components/Payment/Voucher/VoucherByCategory/Voucher_itemBy_Category";
import Patient_Transactions from "./components/Patient/Patient";
import Voucher_Item_report from "./components/Payment/Voucher/VoucherItems/VoucherItems"
export default function App() {
  useEffect(() => {
    console.log("State changed");
  });
  useEffect(() => {
    Chart.register(CategoryScale);
  }, []);

  const AuthUser = useAuthUser();
  
  return(
    <AuthProvider>
      <ToastContainer
position="top-center"
autoClose={2000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss={false}
draggable
theme="light"
/>

    <Header/>

      <div className='content'>

        
        <Routes>
       {/*state ? ( 
 <Route path="/Customerorders" element={<Customerorders />} />
        <Route path="/Customers" element={<Customers />} />
        <Route path="/CustomerInformation" element={<CustomerInformation />} />

        ):null */}
         

          <Route path="/lineitem/:id" element={<Voucher_itemBy_Category />} />
          <Route path="/VoucherItem" element={<Voucher_Item_report />} />
          <Route path="/Patient_Transaction" element={<Patient_Transactions />} />
          <Route path="/Report_Doctor" element={<Treatment_Log_report />} />
          <Route path="/AddImplant" element={<AddImplant />} />
          <Route path="/payee/:id" element={<VoucherByPayee />} />
          <Route path="/Category/:id" element={<Payment_Category />} />
          <Route path="/dashboard/voucher" element={<VoucherDashboard />} />
          <Route path="/Voucher/:id" element={<Voucher />} />
          <Route path="/Vouchers" element={<VoucherInfo />} />
          <Route path="/Voucher" element={<Create_Voucher />} />
          <Route path="/Customers" element={<Customers />} />
          <Route path="/CustomerInformation" element={<CustomerInformation />} />
          <Route path="/TestAuth" element={<TestAuth />} />
          <Route path="/" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
        </Routes>
      </div>
      <Footer/>
      <Loader />
      <Alert type={''} />
    </AuthProvider>
  )
}
