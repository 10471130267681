import React, { useState } from "react";
import customaxios from "../../helpers/axios-helper";

interface Implant {
  implant_Width: number;
  implant_Height: number;
  brand: {
    brandName: string;
  };
  stockLevel: {
    currentStock: number;
  };
}

const AddImplant: React.FC = () => {
  const [implant, setImplant] = useState<Implant>({
    implant_Width: 0,
    implant_Height: 0,
    brand: {
      brandName: "",
    },
    stockLevel: {
      currentStock: 0,
    },
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const names = name.split(".");
  
    if (names.length === 2) {
      const [parent, child] = names;
      setImplant((prevState) => ({
        ...prevState,
        [parent]: {
          ...prevState[parent],
          [child]: value,
        },
      }));
    } else {
      setImplant((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await customaxios.post("/Inventory/AddImplant", implant);
      // reset form fields
      setImplant({
        implant_Width: 0,
        implant_Height: 0,
        brand: {
          brandName: "",
        },
        stockLevel: {
          currentStock: 0,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Width:</label>
        <input
          type="number"
          name="implant_Width"
          value={implant.implant_Width}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label>Height:</label>
        <input
          type="number"
          name="implant_Height"
          value={implant.implant_Height}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label>Brand:</label>
        <input
          type="text"
          name="brand.brandName"
          value={implant.brand.brandName}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <label>Stock Level:</label>
        <input
          type="number"
          name="stockLevel.currentStock"
          value={implant.stockLevel.currentStock}
          onChange={handleInputChange}
        />
      </div>
      <button type="submit">Add Implant</button>
    </form>
  );
};

export default AddImplant;
